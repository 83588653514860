.custom-table {
  width: 100%;
  /* border-collapse: collapse; */
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.smart-table th, .custom-table td {
  padding: 12px 15px;
  font-size: x-large;
}

.smart-table thead th {
  text-align: left;
    border-right-style: double;
    font-size: 21px !important;
}

.custom-table thead th {
  font-weight: bold;
    font-size: 20px;
}

.custom-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.custom-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.custom-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.custom-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.options {
  margin-top: 20px;
}

.option {
  margin-bottom: 10px;
}

.verified-tag {
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.edit-button, .view-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-button:hover, .view-button:hover {
  background-color: #007f63;
}

/* .wrapper .wrapper-content {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  
  font-size: x-large;
  font-weight: 500;
  clip-path: border-box;
  text-align: center;
  padding-left: 32px;
} */
