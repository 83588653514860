.offer-card {
  width: 280px;
  height: 160px;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.offer-card:hover {
  transform: scale(1.05);
}

.hover-text {
  position: absolute;
  bottom: -70%; /* Initially hidden, moved below the card */
  left: 0;
  right: 0;
  height: 30%; /* Takes up the bottom 30% of the card */
  background-color: rgba(0, 0, 0, 0.6); /* Translucent background */
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: bottom 0.5s ease; /* Smooth transition for the wipe up effect */
  cursor: pointer;
}

.hover-text.show {
  bottom: 0; /* Slides up to show */
}

.hover-text p {
  margin: 0;
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 50px;
  cursor: pointer;
}
