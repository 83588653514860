input::-webkit-file-upload-button {
  visibility: hidden;
}
input::before {
  content: 'Choose file';
  color: black;
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  /* -webkit-user-select: none; */
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
input[type='file'] {
  color: rgba(255, 255, 255, 0)
}
input:hover::before {
  border-color: black;
}
input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.head{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #ffa8a8;
}
.onClick {
    position: absolute;
    top: 0%;
    left: 1%;
    outline: none;
    color: transparent;
    width: 100%;
    box-sizing: border-box;
    padding: 70px 70px;
    cursor: pointer;
    transition: 0.5s;
    z-index: 3;
}



.onClick::before {

    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 70px 70px;
    position: absolute;
    width: 1px;
}

.onClickBg {
    position: absolute;
    top: 0%;
    left: 1%;
    outline: none;
    color: transparent;
    width: 100%;
    box-sizing: border-box;
    padding: 250px 250px;
    cursor: pointer;
    transition: 0.5s;
    z-index: 1;
}

.onClickBg::before {

    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 250px 250px;
    position: absolute;
    width: 1px;
}


::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #6b7c96;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2d343d, #5b697a);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2d343d, #5b697a);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #94acc3;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4c6b89, #86a1bb);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3d6ba8, #825edd);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #597da0;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #7c2fca, #351456);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #7c2fca, #351456);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}