.background {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    36deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(37, 41, 58, 1) 50%,
    rgba(48, 56, 76, 1) 100%
  );
  height: 100vh;
  width: 100%;
  display: grid;
  justify-content: center;
}

.group {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 15px;
  padding: 0 10px;
}

.input {
  width: 300px;
  background-color: transparent;
  border-radius: 10px;
  padding: 12px 0px;
  outline: none;
}

.LoginForm {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 350px;
  justify-items: center;
}

.wrapper1 {
  height: 450px;
  width: 800px;
  display: flex;
  overflow: hidden;
  background-color: aliceblue;
  box-shadow: -8px 13px 100px rgba(0, 0, 0, 0.5);
}

.btn {
  color: #d0fffa;
  background-color: #004bbb;
  outline: none;
  padding: 10px 20px;
  border-radius: 12px !important;
  font-weight: 500;
  margin: 15px 0 !important;
  border: 3px solid #004bbb;
  transition: all 0.5s ease-in-out;
}

.btn:hover {
  color: white;
  background-color: rgb(255, 0, 51);
  border: 3px solid rgb(255, 0, 81);
}

.SideImage {
  width: 400px;
  z-index: 2;
  background-image: url("/public/Login.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Profile {
  background-image: url("/public/Profile.png");
  height: 90px;
  width: 90px;
  background-size: contain;
  background-repeat: no-repeat;
}

.h3 {
  padding: 10px;
  margin: 0;
}
