/* Centering and basic styles */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  background-color: #fff;
  margin: 50px;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Button Group Styling */
.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.page-btn {
  padding: 12px 24px;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
  background-color: #fff;
  color: #007bff;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.page-btn:hover {
  background-color: #007bff;
  color: white;
}

.page-btn.active {
  background-color: #ec5858;
  color: white;
}

/* Form Styling */
.form {
  margin-top: 30px;
}

.form-field {
  margin-bottom: 20px;
}

.form-field label {
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
}

.form-field input,
.form-field textarea {
  width: 97%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  resize: vertical;
  height: 100px;
}

button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
