.sidebar {
  position: fixed;
  min-height: 100vh;
  height: 100%;
  top: 0;
  width: 60px;
  background: rgb(14, 23, 33);
  display: flex;
  z-index: 4;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: width 0.3s ease-in-out;
}

.sidebar.expanded {
  width: 17rem;
}

.boxicon-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease-in-out;
}

.boxicon-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.boxicon {
  margin: 0.8rem;
  animation-iteration-count: 1;
  z-index: 2;
  transition: transform 0.2s ease-in-out;
}

.boxicon-container:hover .boxicon {
  transform: scale(1.1);
}

.first-and-last-trash-fix {
  margin: 0.8rem;
}

.active {
  background-color: #ffc1c1;
}

.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 95%;
  background-color: white;
  opacity: 1;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: -0.25rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.boxicon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 95%;
  background-color: white;
  opacity: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: -0.25rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.boxicon-container:hover > .boxicon::after {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.sidebar > *:first-child {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sidebar > *:last-child {
  margin-top: auto;
  margin-bottom: 2rem;
}

.final-icon {
  background-color: rgb(81, 0, 255);
}

.description {
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5rem;
  z-index: 10;
  font-weight: bold;
  margin: 0;
  color: white;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.boxicon-container:hover .description {
  opacity: 1;
  transform: translateY(-50%) translateX(5px);
}

.active-description {
  color: rgb(0, 0, 0);
}

.show-description {
  opacity: 1;
}

.container {
  display: flex;
}

.text {
  color: white;
  z-index: 100;
  margin: 0 auto;
  align-self: center;
  font-size: 32px;
}

@media screen and (max-height: 550px) {
  .boxicon {
    margin-left: 1.2rem;
  }
  .first-and-last-trash-fix {
    margin-left: 1.2rem;
  }
}
