.offer-list {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(400px, 1fr)
  ); /* Responsive grid */
  gap: 10px; /* Space between cards */
  padding: 90px;
  justify-items: center; /* Center cards horizontally */
}

@media (min-width: 900px) {
  .offer-list {
    grid-template-columns: repeat(
      5,
      1fr
    ); /* Maximum 3 columns for larger screens */
  }
}
.heading {
  text-align: center;
  margin: 40px 0;
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
}


